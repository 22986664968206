export const localStorageHandler = {
    setItem: (key, value) => {
      try {
        const serializedValue = typeof value === "string" ? value : JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
      } catch (error) {
        console.error(`Error setting localStorage key "${key}":`, error);
      }
    },
  
    getItem: (key) => {
      try {
        const value = localStorage.getItem(key);
        return value ? (value.startsWith("{") || value.startsWith("[") ? JSON.parse(value) : value) : null;
      } catch (error) {
        console.error(`Error getting localStorage key "${key}":`, error);
        return null;
      }
    },
  
    removeItem: (key) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error(`Error removing localStorage key "${key}":`, error);
      }
    },
  };
  