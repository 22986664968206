import React, { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import config from "../../src/config/index";

import axios from "axios";

import resdata from "../data/ResData";

import LoadingWidget from "../components/LoadingWidget";

export const Customer360Contex = createContext();

export const Customer360Provider = (props) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`${config.backendHost}customers/360/${id}?include_child_customers_events=No&include_child_tenants=No&active=Yes&inactive=No`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setIsLoading(false);
          res.deleted_addresses = [];
          res.deleted_contacts = [];
          res.deleted_address_serial_numbers = [];
          res.added_customer_notes = "";
          res.added_exchange_relationship_notes = "";


          setData(res);
        })
        .catch(() => {
          setIsLoading(false);
          setData(resdata);
        });
    } else {
      setIsLoading(false);
      setData(resdata);
    }
  }, [id, token]);

  return (
    <>
      {!isLoading ? (
        <Customer360Contex.Provider value={[data, setData]}>
          {props.children}
        </Customer360Contex.Provider>
      ) : (
        <LoadingWidget />
      )}
    </>
  );
};
